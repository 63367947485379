import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Articles from '../components/articles';
import WorkExperience from '../components/work-experience';
import ProfilePicture from '../images/profile.png';

const IndexPage = () => (
  <Layout>
    <SEO title="CV" />

    <div className="pb-16">
      <div className="mx-auto max-w-screen-lg mx-auto px-4">
        <div class="relative">
          <div class="lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
            <div class="relative sm:py-16 lg:py-0">
              <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                <div class="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                  <img class="absolute inset-0 h-full w-full object-cover" src={ProfilePicture} alt="" />
                  <div class="absolute inset-0 bg-gray-600 mix-blend-multiply" style={{
                    'mix-blend-mode': 'multiply'
                  }}></div>
                  <div class="absolute inset-0 bg-gradient-to-t from-indigo-600 via-indigo-600 opacity-90"></div>
                  <div class="relative px-8">
                    <blockquote class="mt-8">
                      <div class="relative text-lg font-medium text-white md:flex-grow">
                        <p class="relative">2022 shortlisted finalist for the Made in Manchester Awards; Technology and Innovation Professional of the Year.</p>
                      </div>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>

            <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
              <div class="pt-12 sm:pt-16 lg:pt-20">
                <h1 class="text-3xl text-gray-400 font-extrabold tracking-tight sm:text-4xl">Charlotte Rushton</h1>
                <div class="mt-6 text-gray-500 space-y-6">
                  <p className="mb-3">Representative of UN Women UK for ongoing United Nations Congress'.</p>
                  <p className="mb-3">Passion for all things Tech and Digital, with a specialist interest in all things 'Tech for Good'.</p>
                  <p>Pre-pandemic, I was lucky enough to sit on several panels at both Tech/Digital and Female Equality based events; I have also been a guest on Podcasts around these topics. </p>
                
                  <h3 className="mb-4 mt-6 text-base font-semibold tracking-wider text-indigo-600 uppercase">A few extra things... </h3>
                  <p className="mb-3">The Arts have always been a huge interest of mine with a love of all things dance, literature and art.</p>
                  <p className="mb-3">Thanks to starting ballet classes at the age of four, I have been a member of several dance companies and trained in several different disciplines; inevitably leading to formal teacher training with the ability to teach classes of children aged 11 and below... Now I tend to stick to Yoga! </p>
                  <p className="mb-3">My foray into beauty and fashion blogging in my late teens / early 20s has also lead to beauty product reviews published in Stylist Magazine. </p>
                  <p>At the weekends you are most likely to find me shopping, reading or out for tea. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <WorkExperience />

      <div class="relative pt-32">
        <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <h2 class="text-base font-semibold tracking-wider text-indigo-600 uppercase">Some Previous Clients Include;</h2>
          <p class="mt-5 max-w-prose mx-auto text-xl text-gray-500">Manchester Art Gallery, Arts Council England, Staffordshire Police, Coventry University, Eureka! The National Children's Museum, The National Football Museum, Durham County Council, Keele University, British Cycling, KPMG, The Football Association, The Laureus Foundation, AstraZeneca, Pfizer </p>
        </div>
      </div>

      <Articles />
    </div>
  </Layout>
);

export default IndexPage;
