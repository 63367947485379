import React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import Article from './article';

const Articles = ({ isVisible = true }) => {
  const animation = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0)' : 'translateY(40px)',
  });

  return (
    <animated.div className="flex container max-w-screen-lg m-auto px-4 flex-col mt-32" style={animation}>
      <div>
        <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-400 sm:text-4xl sm:leading-10">
          Some People Have Said... 
        </h2>
      </div>

      <div className="mt-12 grid gap-16 border-t-2 border-gray-100 pt-12 lg:grid-cols-3 lg:col-gap-5 lg:row-gap-12 flex-row">
        <Article
          description="In the time we worked together Charlotte showed exceptional project management experience and technical understanding on several complex web builds, working across internal teams and clients side development teams. She excelled beyond her years at time management, client relationship building, problem solving and juggling of finite resource across multiple projects. It would be a pleasure to work with her in the future."
          date="Carl Jackson, Head Of Engineering at Moonpig"
        />

        <Article
          description="Charlotte is undoubtedly one of the best project managers I've had the opportunity to work with. Charlotte made an immediate impact, taking the reins fully on a number of large and complicated web projects. She worked well both with multiple client stakeholders as well as internal teams over several locations. Most importantly, she delivered things on time and on budget.<br /><br />Despite only working together for six months, Charlotte left a lasting impression. It's sometimes tough knowing if you made the right decision employing a project manager, but Charlotte is exceptional. She's detailed, tenacious and full of positive energy. I wouldn't hesitate to recommend her and would love to work with her again."
          date="Jim Moran, Service Delivery Lead at AND Digital"
        />

        <Article
          description="Char is an intelligent, creative thinker. Any digital projects face challenges at times, and Charlotte has been really excellent at addressing these head-on with a problem-solving attitude and refreshing honesty. As a client, the efficacy of my relationship with our project manager is key, and Charlotte has been a great advocate and a delight to work with. She is open-minded and takes the time to do her research, so I've been able to trust the advice she has given us.<br /><br />Beyond all that - she's lively, positive and a pleasure to be around and I sincerely hope I get to work with her again!"
          date=" Sophie Ballinger, Communications Manager at Eureka! The National Children's Museum"
        />
      </div>
    </animated.div>
  );
};

Articles.propTypes = {
  isVisible: PropTypes.bool,
};

Articles.defaultProps = {
  isVisible: true,
};

export default Articles;
