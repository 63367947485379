import React from 'react';

const WorkExperience = () => (
  <div className="flex container max-w-screen-lg m-auto px-4 flex-col mt-32">
    <div>
      <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-400 sm:text-4xl sm:leading-10">
        Work Experience
      </h2>
    </div>

    <div className="mt-12 grid gap-16 border-t-2 border-gray-100 pt-12">
      <dl>
        <div className="md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              HAVAS LYNX Group
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Digital Project Director
              <br />
              May 2020 - Present
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500 mb-3">
              Hybrid role of Project and Account Director, overseeing the delivery of all contracts and accounts. Both from a view of commercial success and client satisfaction.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              As a member of the management team within the Digital Division, I have been able to implement stronger process' around discovery and definition to ensure all outputs truly align with requirements.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              The division is made up of a team of 28, with annual contract value estimated at £3.5m. 5 Direct reports.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Ultimately responsible for the acquisition, definition, delivery and aftercare of all digital workstreams which run through Havas Lynx. Working closely with the Digital Operations Director and Head of Development to ensure that all workstreams are delivered at the highest possible standard, along with ensuring that these hit all client needs.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              As the team are directly accountable for the qualification of our own leads, we are able to develop digital strategies directly with our clients. Owing to the nature of 'Big Pharma' many clients are still on the path of 'Digital Transformation' and as such, we have a huge ability to make an impact with the solutions and strategic recommendations which we implement. As such, extensive time is taken to research, refine and plan all outputs, along with ensuring that all key members of the team (both internal and external) are involved throughout the project process.
            </p>
            <p className="text-base leading-6 text-gray-500">
              <u>Highlight</u>: When joining the team, the Digital division was a shared service within Havas Lynx. Since joining the business, I have worked with the key players within the group to deliver a strong digital offering and cultivate relationships ongoing. As such, the success has been so strong that the team have splintered into a Profit & Loss agency. At present, this 'new' division is the highest billing of all the Lynx teams, re-enforcing the strong growth the team has seen over the past 2 years, following a more refined focus on developing our offering.
            </p>
          </dd>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-6 md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              HAVAS LYNX Group
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Head Ambassador (Volunteer)
              <br />
              March 2021 - Present
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500">
              The Ambassador programme is an integral part of #LynxLife. 
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Ambassadors are representatives from each Division within Lynx (16 in total) working to improve the culture within the business. The Ambassadors are empowered to work closely with the ELT to implement new initiatives such as pro-nouns on email signatures, gender neutral facilitates and Employee Resource Groups. 
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              As Head Ambassador, I am responsible for the development and rollout of all Ambassador initiatives. I work closely with the team to develop the core strategies behind each project, linking these back to business needs, and pitching these into the ELT for sponsorship. Once approved, I support the teams through the delivery process, acting as facilitator and escalation as required. 
            </p>
            <p className="text-base leading-6 text-gray-500">
              <u>Highlight</u>: Working with the Ambassadors to rollout company wide Mental Health awareness training, and the introduction of Mental Health First Aid training over the business for line managers. Over the past 12 months, the team have also raised over £16,000 for our charity partners. 
            </p>
          </dd>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-6 md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              NHS Trust Manchester 
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Pharmacy Assistant (Volunteer)
              <br />
              March 2020 - May 2020
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500">
              Supporting the Manchester Royal Infirmary Hospital during the COVID19 outbreak. 
            </p>
          </dd>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-6 md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              The Hut Group (THG)
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Project Management Team Lead (Digital)
              <br />
              December 2019 - March 2020
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500 mb-3">
              Managing a team of 12 Project Managers spanning over 5 divisions within THG; inclusive of the Executive / C-suite projects. 
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Digital Lead for projects within the Studios and Ingenuity business units, overseeing the architecture and execution of the overarching digital strategy for all externally facing clients, along with upskilling the team around best practice for ecommerce strategy.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Ownership of the execution of all New Business ventures, within the Studios team, and a member of the core Pitch team for all Digital/ Tech proposals. 
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Member of the Management Team within Studios. 
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Whilst an ecommerce business, the delivery of external client contracts was a young business unit within THG, as such, myself and the Head of Digital Strategy worked closely to glean on previous experience to implement a more 'traditional' agency set up and model allowing for the smooth delivery of several high value contracts.
            </p>
            <p className="text-base leading-6 text-gray-500">
              <u>Highlight</u>: Introducing a new end-to-end digital delivery process which spanned over several facets of the business, something which had not been in place for tech and digital workstreams. This included the creation of a costing matrix/ calculator which is still used to ensure New Business opportunities are commercially viable. 
            </p>
          </dd>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-6 md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              Dept / e3 Creative 
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Senior Digital Project Manager
              <br />
              June 2019 - November 2019
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500 mb-3">
              Senior Digital Project Manager, and member of the MGMT team, within the Experience Design division of Dept.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Along with overseeing my own projects, I was also directly responsible for the Delivery Team of 5; both their line management and ultimately accountable for the execution of their programmes of work. Escalation point for all Projects. 
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Projects had a significant focus on Strategy, UX and UI with large phases of research and experience definition to inform the technical backlog.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              This large focus on upfront Strategy ensured that the long term roadmap for all engagements was multifaceted to create a holistic digital solution.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Additionally, member of the core New Business team with the ELT.
            </p>
            <p className="text-base leading-6 text-gray-500">
              <u>Highlight</u>: Implementing Operational changes, specifically, around reporting, financial forecasting and ongoing resource management to empower the teams to feel they had autonomy over outputs. Moving project costs from Fixed to T&M to ensure commercial viability. 
            </p>
          </dd>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-6 md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              Delete 
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Client Partner
              <br />
              November 2018 - June 2019
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500">
              Working across two key accounts in the capacity Senior Project Manager and Senior Account Manager.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Main account was a, large scale, French pharmaceutical client with annual agency contract values of €4.5 million, managing a variety of work streams from prototyping to multilingual platforming within Sitecore.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Secondary account was a high profile sporting event client with a contract value of £650,000 with a site initially built in a single Kentico instance, with 8 multilingual / multi-regional instances rolled out with 3 months. 
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Whilst at Delete, I also line managed two Delivery Executives who supported with the delivery of both contracts - along with managing their own smaller accounts (circa £100K value per client), on which, I acted as an escalation.  
            </p>
            <p className="text-base leading-6 text-gray-500">
              <u>Highlight</u>: Working on site with client bi-weekly in Lille to achieve a truly collaborative programme of work - this also lead to securing a full time retained team, of 10 people in mixed disciplines, with the client, with additional budget secured for overflow activity for up to 6 other team members per month. 
            </p>
          </dd>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-6 md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              Jaywing
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Digital Project Manager (Contract)
              <br />
              May 2018 - November 2018
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500">
              Contract Project Manager role overseeing and planning the UK (Leeds and Sheffield) and Australian offices digital projects.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              A full Ecommerce re-build for a client with over £10m annual online revenue.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Custom MVC solution for a leading financial company - including a large UX and planning phase and managing a team across two locations.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Managing the development of a global brand strategy (both on and offline) for a leading health & leisure provider.
            </p>
            <p className="text-base leading-6 text-gray-500">
              <u>Highlight</u>: Architecting a digital project process which has been rolled out across the various offices - this included a contact strategy, roll out of project planning software and development of documentation to be used throughout the project process. Previously, process was only in place for brand and print based projects.
            </p>
          </dd>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-6 md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              Freelance
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Fulltime
              <br />
              January 2017 - May 2018
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500">
              Fulltime Freelance, working with a variety of clients - main specialisms / service offerings being; Digital Strategy, Data Insight, SEO Management, PPC Management, Content Creation, Project Management.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Where projects required additional expertise, such as development, I created custom project teams in line with requirements.
            </p>
            <p className="text-base leading-6 text-gray-500">
              <u>Highlight</u>: Working with WeForest, a charitable organisation, on a pro-bono project to architect a web app which was be rolled out across all of their other partner and donor sites. This was developed with data capture in mind, and 'tech for good' being at the forefront of this development.
            </p>
          </dd>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-6 md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              Manchester Metropolitan University 
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Digital Project and Insight Officer (Contract)
              <br />
              January 2017 - April 2017 
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500">
              Working within the Web Team, this contract included creating new processes for the existing team, including, but not limited to; project reporting, implementation of Jira and introduction of risk management for all tasks.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              This contract focused heavily around data analysis, primarily within the Google Suite, and creating a more robust Digital Strategy for the University. 
            </p>
            <p className="text-base leading-6 text-gray-500">
              <u>Highlight</u>: Working closely with the Digital Marketing Director to create an overarching Digital Strategy and being directly responsible for creating a custom GA dashboard which could be leveraged by all Marketing Officers within the business. This also included rolling out training around reporting and insights to ensure that metrics could be leveraged successfully.
            </p>
          </dd>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-6 md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              Reading Room 
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Project and Operations Manager
              <br />
              June 2014 - December 2016 
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500">
              Managing digital solutions from inception through to delivery (and beyond); specialising in the Arts and Culture industry.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Elected Reading Room Manchester Staff Representative within the wider Idox Plc group to represent and support the Manchester team, including consulting during a large redundancy period. 
            </p>
            <p className="text-base leading-6 text-gray-500">
              <u>Highlight</u>: Supporting the Manchester Divisional Director in all resource planning, budgeting and risk escalation. Acting as deputy within the Manchester Division, I was able to make a large impact upon billing and also managed all X-Charges across the wider group, both UK and AsiaPac.
            </p>
          </dd>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-6 md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              IDOX Software 
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Elections Technical Support Manager (Secondment)
              <br />
              March 2016 - July 2016 
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500">
              Working on the 2016 Scottish Parliamentary Elections, and the EU Referendum as a Technical Support Manager. 
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              For this role, I was based in Glasgow for the entirety.
            </p>
            <p className="text-base leading-6 text-gray-500">
              <u>Highlight</u>: The client, East Ayrshire Council, had a 100% success rate and were one of the first constituencies in Scotland to complete the count, on both occasions.
            </p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
);

export default WorkExperience;
